import MensAIAssisted from "../assets/images/men images/image1.png";
import MensAIClassic from "../assets/images/men images/image8.jpg";
import WomensAIAssisted from "../assets/images/Women/image1.jpg";
import WomensAIClassic from "../assets/images/Women/image7.jpg";
import AILive from "../assets/images/news women/image6.png";
import AIAssistedLive from "../assets/images/news women/image6.png";
import { Assignment, Home, PlayCircle, Policy } from "@mui/icons-material";
import India from "../assets/images/india.jpg";

import USA from "../assets/images/usa.jpg"
import Canada from "../assets/images/canada.jpg"
import Japan from "../assets/images/japan.jpg"
import China from "../assets/images/china.jpg"

export const drawerWidth = 240;
export const AiTypes = [
    {
        title: AILive,
        content: 'AI Live News Channel',
        description:"Tune in to real-time news from around the globe, tailored to your country selection, with our AI-powered Live News Channel.",
        selected: false,
        id: 'newsai'

    },
    {
        title: AIAssistedLive,
        content: 'AI Assisted News Channel',
        description:"Upload any text and receive insightful AI-generated responses, transforming your input into an engaging news experience.",
        selected: false,
        id: 'newswithoutai'
    },
    {
        title: MensAIAssisted,
        content: "Men's AI-Assisted Video",
        description:"Men can upload text, photos, or video and receive personalized AI-generated news content, turning their inputs into an interactive visual story",
        selected: false,
        id: 'menai'
    },
    {
        title: WomensAIAssisted,
        content: "Women's AI-Assisted Video.",
        description:"Women can contribute text, photos, or videos to receive AI-crafted news stories, tailored to create a personalized visual narrative.",
        selected: false,
        id: 'womenai'
    },
    {
        title: MensAIClassic,
        content: "Men's Classic Video.",
        description:"Men can upload text,photo or video and watch as it is seamlessly converted into a video, maintaining the original essence without AI alterations.",
        selected: false,
        id: 'menwithoutai'
    },
    {
        title: WomensAIClassic,
        content: "Women's Classic Video.",
        description:"Women can upload text,photo or video, and watch as they are transformed into classic, unaltered visual narratives.",
        selected: false,
        id: 'womenwithoutai'
    },

];

export const NaveMenu = [
    {
        icon: <Home sx={{ color: "#8f8e8f" }} />,
        name: "Home",
        path: "/newsai",
        selected: false
    },
    {
        icon: <Policy sx={{ color: "#8f8e8f" }} />,
        name: "Privacy Policy",
        path: "/privacy-policy",
        selected: false
    },
    {
        icon: <Assignment sx={{ color: "#8f8e8f" }} />,
        name: "Terms & Condition",
        path: "/terms-condition",
        selected: false
    }
]

export const CountryList = [
    {
        img: India,
        title: 'India',
        id: 'India',
        selected: false
    },
    {
        img: USA,
        title: 'USA',
        id: 'USA',
        selected: false
    },
    {
        img: Canada,
        title: 'Canada',
        id: 'Canada',
        selected: false
    },
    {
        img: Japan,
        title: 'Japan',
        id: 'Japan',
        selected: false
    },
    {
        img: China,
        title: 'China',
        id: 'China',
        selected: false
    }
]

export const PrivacyPolicyList = [
    {
        heading: "1.Introduction ",
        description: "Infogen Labs values the privacy of users of Info-news.ai. This Privacy Policy explains how we collect, use, disclose, and safeguard your information. "
    },
    {
        heading: "2.Information Collection ",
        description: "We may collect personally identifiable information, such as your name and email address, and non-personally identifiable information, such as device ID and IP address. "
    },
    {
        heading: "3.Use of Information ",
        description: "We use your information to operate, maintain, and improve our App, to develop new services, and to communicate with you. "
    },
    {
        heading: "4.Information Sharing",
        description: "We do not sell, rent, or lease your personal information to third parties. We may disclose your information as required by law, or to protect our rights. "
    },
    {
        heading: "5.Security of Information",
        description: "We implement reasonable security measures to protect your information, but we cannot guarantee its absolute security."
    },
    {
        heading: "6.Cookies and Tracking Technologies",
        description: "We may use cookies and similar tracking technologies to track your activity on our App and hold certain information. "
    },
    {
        heading: "7.Children's Privacy",
        description: "Our App does not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13."
    },
    {
        heading: "8.Changes to Privacy Policy",
        description: "We may update our Privacy Policy periodically. You are advised to review this Privacy Policy periodically for any changes."
    },
    {
        heading: "9.Contact Us",
        description: "If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at sales@infogen-labs.com or botgeniusplusai@gmail.com  "
    }
]


export const TermsAndConditionsList:any = [
    {
        heading: "1.Introduction ",
        description: "Welcome to Info-news.ai by Infogen Labs! These Terms and Conditions govern your use of our application and services."
    },
    {
        heading: "2.Acceptance of Terms ",
        description: "By accessing or using our App, you confirm your agreement to be bound by these Terms. If you do not agree to these Terms, you may not access or use the App. "
    },
    {
        heading: "3.Changes to Terms",
        description: "Infogen Labs may modify these Terms at any time, and such modifications will be effective immediately upon posting the modified Terms on the App."
    },
    {
        heading: "4.Content and Services",
        description: "We provide a variety of AI-based news services, including but not limited to AI live news, AI-assisted news, and AI-assisted videos. "
    },
    {
        heading: "5.User Contributions",
        description: "You retain all intellectual property rights in any content you upload to the App, but you grant us a license to use, reproduce, modify, and display your content in connection with the App. "
    },
    {
        heading: "6.Prohibited Conduct",
        description: "Users are prohibited from using the App for unlawful purposes, infringing on intellectual property rights, or uploading harmful or offensive content. "
    },
    {
        heading: "7.Disclaimer of Warranties",
        description: "The App and its services are provided 'as is,' without any warranties of any kind, either express or implied. "
    },
    {
        heading: "8.Limitation of Liability",
        description: "To the fullest extent permitted by applicable law, Infogen Labs shall not be liable for any indirect, incidental, or consequential damages."
    },
    {
        heading: "9.Governing Law",
        description: "These Terms are governed by and construed in accordance with the laws of the jurisdiction in which Infogen Labs is located. "
    },
    {
        heading: "10.Contact Information",
        description: "For any questions about these Terms, please contact us at sales@infogen-labs.com or botgeniusplusai@gmail.com  "
    },
]

export const navMenu =[
    {id:'newsai',name:"AI Live News Channel",icon:""},
    {id:'newswithoutai',name:"AI Assisted News Channel",icon:""},    
    {id:'intro',name:"Intro",icon:<PlayCircle fontSize="small"/>},
    {id:'logout',name:"Log Out",icon:""},
];

export const aiFeatures178 =[
    {id:"ElonMusk",selcted:false,name:"Elon Musk",link:"https://newsaii.s3.amazonaws.com/178_Elon_Musk_infogen_news.mp4"},
    {id:"Tesla",selcted:false,name:"Tesla",link:"https://newsaii.s3.amazonaws.com/178_Tesla_infogen_news.mp4"},
    
]

export const aiFeatures179 =[
    {id:"AI",selcted:false,name:"AI",link:"https://newsaii.s3.amazonaws.com/179_AI_infogen_news.mp4"},
    {id:"VideoGames",selcted:false,name:"Video Games",link:"https://newsaii.s3.amazonaws.com/179_Video_Games_infogen_news.mp4"},
    {id:"Salesforce",selcted:false,name:"Salesforce",link:"https://newsaii.s3.amazonaws.com/179_Salesforce_infogen_news.mp4"},
    {id:"MarkBenioff",selcted:false,name:"Mark Benioff",link:"https://newsaii.s3.amazonaws.com/179_Mark_Benioff_infogen_news.mp4"},
]

export const aiFeatures138 =[
    {id:"AndhraPradesh",selcted:false,name:"Andhra Pradesh",link:"https://newsregional.s3.amazonaws.com/138_Andhra_Pradesh_infogen_news.mp4"},
    {id:"ArunachalPradesh",selcted:false,name:"Arunachal Pradesh",link:"https://newsregional.s3.amazonaws.com/138_Arunachal_Pradesh_infogen_news.mp4"},
    {id:"Assam",selcted:false,name:"Assam",link:"https://newsregional.s3.amazonaws.com/138_Assam_infogen_news.mp4"},
    {id:"Bihar ",selcted:false,name:"Bihar ",link:"https://newsregional.s3.amazonaws.com/138_Bihar_infogen_news.mp4"},
    {id:"Chattisgarh ",selcted:false,name:"Chattisgarh ",link:"https://newsregional.s3.amazonaws.com/138_Chhattisgarh_infogen_news.mp4"},
    {id:"Goa ",selcted:false,name:"Goa ",link:"https://newsregional.s3.amazonaws.com/138_Goa_infogen_news.mp4"},
    {id:"Gujarath ",selcted:false,name:"Gujarath ",link:"https://newsregional.s3.amazonaws.com/138_Gujarat_infogen_news.mp4"},
    {id:"Haryana ",selcted:false,name:"Haryana ",link:"https://newsregional.s3.amazonaws.com/138_Haryana_infogen_news.mp4"},
    {id:"HimachalPradhesh ",selcted:false,name:"Himachal Pradhesh ",link:"https://newsregional.s3.amazonaws.com/138_Himachal_Pradesh_infogen_news.mp4"},
    {id:"Jharkhand ",selcted:false,name:"Jharkhand ",link:"https://newsregional.s3.amazonaws.com/138_Jharkhand_infogen_news.mp4"},
    {id:"Karnataka ",selcted:false,name:"Karnataka ",link:"https://newsregional.s3.amazonaws.com/138_Karnataka_infogen_news.mp4"},
    {id:"Kerala ",selcted:false,name:"Kerala ",link:"https://newsregional.s3.amazonaws.com/138_Kerala_infogen_news.mp4"},
    {id:"MadhyaPradesh ",selcted:false,name:"Madhya Pradesh ",link:"https://newsregional.s3.amazonaws.com/138_Madhya_Pradesh_infogen_news.mp4"},
    {id:"Maharastra ",selcted:false,name:"Maharastra ",link:"https://newsregional.s3.amazonaws.com/138_Maharashtra_infogen_news.mp4"},
    {id:"Manipur ",selcted:false,name:"Manipur ",link:"https://newsregional.s3.amazonaws.com/138_Manipur_infogen_news.mp4"},
    {id:"Meghalaya ",selcted:false,name:"Meghalaya ",link:"https://newsregional.s3.amazonaws.com/138_Meghalaya_infogen_news.mp4"},
    {id:"Mizoram ",selcted:false,name:"Mizoram ",link:"https://newsregional.s3.amazonaws.com/138_Mizoram_infogen_news.mp4"},
    {id:"Nagaland ",selcted:false,name:"Nagaland ",link:"https://newsregional.s3.amazonaws.com/138_Nagaland_infogen_news.mp4"},
    {id:"Odisha ",selcted:false,name:"Odisha ",link:"https://newsregional.s3.amazonaws.com/138_Odisha_infogen_news.mp4"},
    {id:"Punjab ",selcted:false,name:"Punjab ",link:"https://newsregional.s3.amazonaws.com/138_Punjab_infogen_news.mp4"},
    {id:"Rajasthan ",selcted:false,name:"Rajasthan ",link:"https://newsregional.s3.amazonaws.com/138_Rajasthan_infogen_news.mp4"},
    {id:"Sikkim ",selcted:false,name:"Sikkim ",link:"https://newsregional.s3.amazonaws.com/138_Sikkim_infogen_news.mp4"},
    {id:"Tamilnadu ",selcted:false,name:"Tamilnadu ",link:"https://newsregional.s3.amazonaws.com/138_Tamil_Nadu_infogen_news.mp4"},
    {id:"Telangana ",selcted:false,name:"Telangana ",link:"https://newsregional.s3.amazonaws.com/138_Telangana_infogen_news.mp4"},
    {id:"Tripura ",selcted:false,name:"Tripura ",link:"https://newsregional.s3.amazonaws.com/138_Tripura_infogen_news.mp4"},
    {id:"UttarPradesh ",selcted:false,name:"Uttar Pradesh ",link:"https://newsregional.s3.amazonaws.com/138_Uttar_Pradesh_infogen_news.mp4"},
    {id:"Uttarkhand ",selcted:false,name:"Uttarkhand ",link:"https://newsregional.s3.amazonaws.com/138_Uttarakhand_infogen_news.mp4"},
    {id:"Westbengal ",selcted:false,name:"Westbengal ",link:"https://newsregional.s3.amazonaws.com/138_West_Bengal_infogen_news.mp4"},
    {id:"AndamanandNicobarIslands ",selcted:false,name:"Andaman and Nicobar Islands ",link:"https://newsregional.s3.amazonaws.com/138_Andaman_and_Nicobar_Islands_infogen_news.mp4"},
    {id:"Chandigarh ",selcted:false,name:"Chandigarh ",link:"https://newsregional.s3.amazonaws.com/138_Chandigarh_infogen_news.mp4"},
    {id:"DadraandNagarHaveliandDamanandDiu ",selcted:false,name:"Dadra and Nagar Haveli and Daman and Diu ",link:"https://newsregional.s3.amazonaws.com/138_Dadra_and_Nagar_Haveli_and_Daman_and_Diu_infogen_news.mp4"},
    {id:"Lakshadweep ",selcted:false,name:"Lakshadweep ",link:"https://newsregional.s3.amazonaws.com/138_Lakshadweep_infogen_news.mp4"},
    {id:"Puducherry ",selcted:false,name:"Puducherry ",link:"https://newsregional.s3.amazonaws.com/138_Puducherry_infogen_news.mp4"},
    {id:"Ladakh ",selcted:false,name:"Ladakh ",link:"https://newsregional.s3.amazonaws.com/138_Ladakh_infogen_news.mp4"},
    {id:"Delhi ",selcted:false,name:"Delhi ",link:"https://newsregional.s3.amazonaws.com/138_Delhi_infogen_news.mp4"},


]