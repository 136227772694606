export const getStoragedData = (name:any) =>{
    if (sessionStorage.getItem(name)) {
        if(!sessionStorage.getItem(name)) 
        return  {}
        const locJsonArr = JSON.parse(sessionStorage.getItem(name) || '');
        return locJsonArr
    }
    else {
        return null
    }
}