import {  Box, Button, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import logo from "./assets/images/logo.png"
import { Home, Menu, PlayCircle, VideoCall } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { getStoragedData } from "./utils/Service";
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';

import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import DemoVideo from "./DemoVideo";
import { drawerWidth, navMenu } from "./utils/ExportData";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }
  
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),zIndex:1201,
    backgroundColor:"#fff",
    ...(open && {
      width: `calc(100%)`,
      marginLeft: `${drawerWidth}px`,
      
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
const Navbar = ({open,handleDrawerOpen}:any) => {
    const navigate = useNavigate();
     const[userId,setUserId] = useState(null);
     const [openDemo, setOpenDemo] = useState(false);
     const [selected, setSelected] = useState("newsai");

     const handleButtonClick = (buttonIndex:any) => {
       setSelected(buttonIndex);
       if(buttonIndex == "logout"){
        handleLogOut()
       }else if (buttonIndex == "intro") {
        setOpenDemo(true)
       } else{
        navigate(buttonIndex)
       }
     };

    useEffect(()=>{
        const user_id = getStoragedData('user_id');
        if(user_id) {
          setUserId(user_id)
          navigate('/newsai')
        }else{
            navigate('/login');
        }
    },[])

    const handleLogOut = ()=>{
        sessionStorage.clear();
        navigate('/login');

    }

    return (
        // <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" open={open}>
                <Toolbar>
                <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2 }}
          >
            <Menu sx={{color:"#000"}} />
          </IconButton>
                    <Box sx={{ flexGrow: 1 }}>
                        <img src={logo} alt="logo" />
                    </Box>
                    <Stack spacing={2} direction="row">
                    {userId?
                    <>
                    {/* <Button variant="contained" sx={{ bgcolor: 'purple', color: '#fff' }} onClick={handleLogOut}>Log Out</Button> */}
                      {
                        navMenu.map((item:any)=>(
                          <Button key={item.id} color="secondary" variant={selected === item.id ? "contained":"text"}  onClick={()=>handleButtonClick(item.id)} >{item.name}</Button>
                        ))
                      }
                    </>
                    :
                    <Button variant="contained" sx={{ bgcolor: 'purple', color: '#fff' }}>Log In</Button>}
                
                
                {/* <Button variant="contained"  onClick={()=>setOpenDemo(true)}><PlayCircle fontSize="small"/>  Intro</Button> */}
                </Stack>
                
                <DemoVideo open={openDemo} handleClose={()=>setOpenDemo(false)}/>
                </Toolbar>
            </AppBar>
        // </Box>
    )
}

export default Navbar;