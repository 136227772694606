import { useState } from "react"
import { TermsAndConditionsList } from "./utils/ExportData";
import { Box, Typography } from "@mui/material";

const TermsAndCondition = () => {
    const [itemData, setItemData] = useState(TermsAndConditionsList)
    return (
        <>
            <Box >
                <Typography sx={{ width: '100%', textAlign: "center",fontFamily:"Georama"  }} variant="h4" gutterBottom>
                   <b> Terms and Conditions</b>
                </Typography>
            </Box>
            <Box>
                {itemData.map((data: any) =>
                    <Typography variant='h6' sx={{ width: '100%', textAlign: "left", mb: 1,fontFamily:"Georama" }}  gutterBottom >
                        <Typography component="span" variant='h5' sx={{fontFamily:"Georama"}} > <b>{data.heading} :</b></Typography> {data.description}
                    </Typography>

                )}
            </Box>
        </>
    )
}
export default TermsAndCondition