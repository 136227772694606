import { LockOutlined, Save } from "@mui/icons-material";
import { Avatar, Box, Button, Checkbox, Container, Divider, FormControlLabel, Grid, Link, TextField, Typography } from "@mui/material";
import logo from "./assets/images/logo.png"
import { SignInApi } from "./utils/ApiService";
import { useNavigate } from 'react-router-dom';
import signup from './SignUp';
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
const Login =()=>{
    const navigate = useNavigate();
    const [errMsg,setErrMsg] = useState('');
    const [loader,setLoader] = useState(false);
    const handleSubmit = (event:any) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
       setErrMsg('')
        setLoader(true)
        let payload  = {
            EmailId: data.get("email"),
            password: data.get("password"),
          }

        SignInApi(payload).then(
            (data: any) => {
              if (data && data.status != 'error') {
                if (data.token)
                  sessionStorage.setItem('accessToken', JSON.stringify(data.token));
                if (data?.email_id)
                  sessionStorage.setItem('email', JSON.stringify(data.email_id));
                if (data.user_id)
                  sessionStorage.setItem('user_id', JSON.stringify(data.user_id));
                  if (data.PlanId)
                  sessionStorage.setItem('PlanId', JSON.stringify(data.PlanId));
                navigate('/');
              } else {
                setErrMsg(data?.message);
              }
              setLoader(false)
            },
            (err: any) => {
            setErrMsg("Something went wrong")
            setLoader(false);
            }
          );


      };
    return(
<Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          my: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}> */}
            <img src={logo} alt="logo" width={"auto"} />
          {/* </Avatar> */}
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
           {errMsg ? <Box sx={{ mt: 3, textAlign: "center", color: "red" }}>
                <Typography component="div">
                    {errMsg}
                </Typography>   </Box> :''}
            {loader ? <LoadingButton
              loading
              fullWidth
              loadingPosition="start"
              startIcon={<Save />}
              variant="contained"
            >
              Loading...
            </LoadingButton> :
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ my: 2 }}
                
              >
                Sign In
              </Button>}
          <Divider>or</Divider>
          <Button
          
                        
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ my:2 }}
                        href="./SignUp"
                      >
                       REQUEST ACCESS
                      </Button>
          <Grid container>                   
          </Grid>
        </Box>
      </Box>
    </Container>
    )
}

export default Login;