import { Box, Button, Card, CardMedia, Grid } from "@mui/material";
import { useState } from "react";
import NoImage from "./NoImage";

const UploadVideo = ({ keyId, handleChange }: any) => {
    const [image, setImage] = useState<any>(null)
    const [isVideo, setIsVideo] = useState(false)

    const onImageChange = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            let fileType = e.target.files[0].type.startsWith('video/');
            setIsVideo(fileType)
            setImage(URL.createObjectURL(e.target.files[0]));
            handleChange({ [keyId]: e.target.files[0] })
        }
    }
    return (

        <Card
            style={{ position: "relative", width: "100%" }}
        >
            <div
            >
                {/* {image ?
                    (isVideo ? <video controls src={image} height={200} style={{ width: '100%' }}>
                    </video> :
                        <CardMedia
                            sx={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "100%",

                                // height: "auto",
                                zIndex: "1"
                            }}
                            component="img"
                            height="200"
                            image={image}
                            alt="image"
                        />) :

                    <NoImage />} */}
                <input
                    type="file"
                    // accept="image/*"
                    accept=".jpg,.png,.mp4"
                    onChange={onImageChange}
                    hidden
                    id="contained-button-file2"
                />
                <label htmlFor="contained-button-file2">
                    <Button
                        style={{
                            width:"100%"
                            // position: "absolute",
                            // top: "80%",
                            // left: "35%",
                            // transform: "translate(-50%, -50%)"
                        }}
                        className=" bg-btn"
                        variant="contained"
                        component="span"
                    >
                        UPLOAD PHOTO OR VIDEO
                    </Button>
                </label>

            </div>
        </Card>
    )
}

export default UploadVideo;