import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, ImageList, ImageListItem, ImageListItemBar, ListSubheader, Typography } from "@mui/material";
import { useState } from "react";
import { CountryList } from "./utils/ExportData";

const CountryType = ({ keyId, handleChange }: any) => {
    const [itemData, setItemData] = useState(CountryList)

    const handleClick = (val: any) => {
        const newArray = [...itemData];
        newArray.map((item) => {
            item.selected = false;
            if (item.id === val) {
                item.selected = true;
            }

            return item
        })
        setItemData(newArray);
        handleChange({
            [keyId]: val,
            "input_face": "https://botgeniusplusvideos.s3.amazonaws.com/Infogen+Labs+AI+News+anchor.mp4"
        })
    }
    return (
        <Box sx={{ my: 3, textAlign: "center" }}>
            <Typography variant="h4" component="div">
                Select Country
            </Typography>
            <Grid sx={{ justifyContent: "center" }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {itemData && itemData.map((card, index) => (
                    <Grid item key={card.id} xs={12} sm={6} md={2}>
                        <Card sx={{ bgcolor: card.selected ? "#b015b04f" : "" }}>

                            <CardActionArea onClick={() => handleClick(card.id)}>
                                <CardMedia
                                    component="img"
                                    height={"100px"}
                                    image={card.img}
                                    alt={card.title}
                                    sx={{ m: 'auto', mt: 2, width: "100px" }}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {card.title}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>

                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default CountryType;