import { Box, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Save } from "@mui/icons-material";

const GenerateBtn = ({ loader, submit }: any) => {
    return (
        <Box sx={{ display: "flex", justifyContent: 'center' }}>
            {loader ?
                <LoadingButton
                    loading
                    loadingPosition="start"
                    startIcon={<Save />}
                    variant="contained"
                >
                    Loading...
                </LoadingButton>
                : <Button
                    onClick={submit}
                    variant="contained"
                    color="primary"
                >
                    GENERATE VIDEO
                </Button>}
        </Box>
    )
}

export default GenerateBtn;