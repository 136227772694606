import { Box, Typography } from "@mui/material"
import { PrivacyPolicyList } from "./utils/ExportData";
import { useState } from "react";


const PrivacyPolicy = () => {
    const [itemData, setItemData] = useState(PrivacyPolicyList)
    return (

        <Box >
            <Typography sx={{ width: '100%', textAlign: "center",fontFamily:"Georama" }} variant="h4" gutterBottom>
                <b>Privacy Policy</b>
            </Typography>

            <Box>
                {itemData.map((data: any) =>
                    <Typography sx={{ width: '100%', textAlign: "left", mb: 1,fontFamily:"Georama" }} variant="h5" gutterBottom >
                        <Typography component="span" variant="h5" sx={{fontFamily:"Georama"}} > <b>{data.heading}</b>: </Typography> {data.description}
                    </Typography>

                )}
            </Box>
        </Box>
    )
}

export default PrivacyPolicy;