
import { Drafts, Home, Inbox, Mail } from '@mui/icons-material';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { NaveMenu } from './utils/ExportData';
import { useEffect, useState } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';

const SideMenu = () => {
  const [menuList, setMenuList] = useState(NaveMenu)
  const navigate = useNavigate();
  const location = useLocation();

  
  useEffect(()=>{
    let menuListClo = menuList;
    if(location.pathname){
      menuListClo = menuListClo.map((e:any,i)=>{
        if (location.pathname == e.path) {
          e.selected = true;          
        } else{
          e.selected = false;
        }
        return e
      })
      setMenuList(menuListClo)
    }
  },[location])
  
  return (
    <List>
      {
        menuList && menuList.map((item, index) => (
          <ListItem key={"nav-" + index}>
            <ListItemButton
            onClick={()=>navigate(item.path)}
              selected={item.selected}
              sx={{
                borderRadius: 2,
                "&.Mui-selected": {
                  backgroundColor: "#a639a6",
                  color: "#fff",
                },
                // "&.Mui-focusVisible": {
                //   backgroundColor: "#800080"
                // },
                "&.Mui-selected:hover": {
                  backgroundColor: "#800080"
                }
              }}
            >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))
      }
    </List>

  )

}

export default SideMenu;

