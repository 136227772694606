import { Mic, MicOff, Save } from "@mui/icons-material";
import { Box, Button, IconButton, InputBase, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import GenerateBtn from "./GenerateBtn";

declare global {
    interface Window {
        SpeechRecognition: any;
        webkitSpeechRecognition: any
    }
}

const TextBox = ({ keyId, handleChange, submit, loader }: any) => {
    const [inputValue, setInputValue] = useState('');
    const [recognition, setRecognition] = useState<any>({});
    const [recognizing, setRecognizing] = useState(false);

    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        setRecognition(new SpeechRecognition())
    }, []);

    const inputChange = (e: any) => {
        setInputValue(e.target.value)
        handleChange({ [keyId]: e.target.value })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setInputValue("")
        submit()
    };

    const startRecording = () => {
        if (inputValue.length) {
            let inputMic = inputValue;
            inputMic += ' ';
            setInputValue(inputMic);
        }

        if (recognizing) {
            recognition.stop();
            setRecognizing(false)
        }
        else {
            recognition.start();
            setRecognizing(true)
        }
    }

    recognition.onresult = (event: any) => {
        let current = event.resultIndex;
        let transcript = event.results[current][0].transcript;
        let mobileRepeatBug = (current == 1 && transcript == event.results[0][0].transcript);
        if (!mobileRepeatBug) {
            let inputMic = inputValue
            inputMic += transcript;
            setInputValue(inputMic)
        }
    }
    return (
        <Box sx={{ my: 3, width: "100%" }}>
            <Paper
                component="form"
                onSubmit={handleSubmit}
                sx={{ p: '2px 4px', mb: 2, display: 'flex', alignItems: 'center', width: "100%" }}
            >
                <IconButton sx={{ p: '10px' }} aria-label="menu" onClick={(e) => { startRecording() }}>
                    {recognizing ? <Mic sx={{ color: "#1976d2" }} /> : <MicOff />}
                </IconButton>
                <InputBase
                    fullWidth
                    sx={{ ml: 1, flex: 1, }}
                    inputProps={{ 'aria-label': 'Enter your text..' }}
                    placeholder="Enter your text.."
                    value={inputValue}
                    onChange={inputChange}
                />
            </Paper>
            <GenerateBtn loader={loader} submit={handleSubmit} />
        </Box>
    )
}

export default TextBox;