import { Box, Button, Grid } from "@mui/material";

const ResultVideo = ({ output }: any) => {

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container >
                <Grid item md={12}>
                    <div dangerouslySetInnerHTML={{ __html: output }} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ResultVideo;