const baseUrl ="https://api.info-news.ai/"

export const retryFetch = async (
    url: any,
    options: any,
    retries = 5,
    delay = 200
  ) => {
    try {
      const response = await fetch(url, options);
      if (response.status === 404 && retries > 0) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(retryFetch(url, options, retries - 1, delay));
          }, delay);
        });
      }
      return response;
    } catch (error) {
      if (retries === 0) {
        throw error;
      }
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(retryFetch(url, options, retries - 1, delay));
        }, delay);
      });
    }
  };


  export const fetchWithRetry: any = async (
    url: any,
    options: any,
    retries = 5
  ) => {
    try {
      const response = await fetch(url, options);
      if (response.status === 404 && retries > 0) {
        return fetchWithRetry(url, options, retries - 1);
      }
      return response;
    } catch (error) {
      if (retries <= 0) {
        throw error;
      }
      return fetchWithRetry(url, options, retries - 1);
    }
  };

export const commonPostApi = async (link:any,application_description: any) => {
    try {
      const response:any = await retryFetch(
        baseUrl+link,
        {
          method: 'POST',
  
          body: application_description,
        }
      );
  
      if (response.status == 200 ) {
        return await response.text();
      }
      throw new Error('Something went wrong');
    } catch (error) {
      console.error(error);
      console.error({});
      return null;
    }
  };

  export const SignInApi = (parse: any) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(parse),
      };
  
      fetchWithRetry(baseUrl+'login', requestOptions)
        .then((response: any) => response.json())
        .then(
          (data: any) => {
            resolve(data);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  };

  export const CreateUser = (parse: any) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
  
        body: JSON.stringify(parse),
      };
      fetchWithRetry(baseUrl+'createuser', requestOptions)
        .then((response: any) => response.json())
        .then(
          (data: any) => {
            resolve(data);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  };
