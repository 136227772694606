import { Alert, Avatar, Box, Button, Card, Checkbox, Container, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, Link, MenuItem, Select, Stack, TextField, Tooltip, Typography } from "@mui/material";
import logo from "./assets/images/logo.png"
import { CreateUser } from "./utils/ApiService"
import { useNavigate } from 'react-router-dom';
import { Apartment, LiveHelpOutlined, LocalPhone, MailOutlineTwoTone, PersonOutlineOutlined, SecurityOutlined } from "@mui/icons-material";
import { useFormik } from 'formik';
import { useState } from "react";
import { signupSchema } from "./utils/validationSchema";

const create_account = {
  fontSize: '30px',
  fontWeight: 'bold',
};
const inputStyle = {
  // padding: '8px 10px ',
  // color: '#000',
  // borderRadius: '40px',
  WebkitBoxShadow: "0 0 0 1000px white inset",
  padding: "13px  18px",
  fontSize: "14px",
};

const Signup = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('')
  const SubmitForm = (e: any) => {
    setError(false);
    CreateUser(values)
      .then((res: any) => {
        if (res?.token)
          sessionStorage.setItem('accessToken', JSON.stringify(res?.token));
        if (res?.user_id)
          sessionStorage.setItem('user_id', JSON.stringify(res?.user_id));
        if (res?.PlanId)
          sessionStorage.setItem('PlanId', JSON.stringify(res?.PlanId));

        if (res.status != 'error') {
          // navigate('/signin');
          setUserName(values.username)
        } else {
          setError(true);
          setErrMsg(res?.message);
        }
      })
      .catch((err) => {
        console.error({ err });
        setError(true);
      });
  };
  const { values, errors, handleSubmit, handleBlur, touched, handleChange } = useFormik({
    initialValues: {
      username: '',
      industry: '',
      purpose: '',
      email: '',
      phone_no: '',
      // password: '',
      secretquestion: '',
      secretanswer: ''
    },
    validationSchema: signupSchema,
    onSubmit: SubmitForm,
  });
  const [error, setError] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 3,
          my: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >

        <img src={logo} alt="logo" width={"auto"} />

        <Box >

          <Typography style={create_account}>
            Request Access
          </Typography>
        </Box>
        {userName ? <Box >
          <Alert severity="success" sx={{my:2}}>
            <Box>
              <strong>Request Submitted!</strong>  Our Info-News experts are now reviewing your information. You'll hear from us soon - we can't wait to start this exciting journey with you. Thank you for your interest!"
            </Box>
           
          </Alert>
          <Button

            fullWidth
            variant="contained"
            color="primary"
            href="./login"
          >
            Back to Login
          </Button>
        </Box>
          :

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

            <TextField
              margin="normal"
              label="Name"
              variant="outlined"
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              value={values.username}
              error={touched.username && Boolean(errors.username)}
              helperText={touched.username && errors.username}
              inputProps={{ style: inputStyle }}
              InputProps={{
                style: { borderRadius: '12px' },
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonOutlineOutlined />
                  </InputAdornment>
                ),
              }}
            />


            <TextField
              margin="normal"
              label="Email id"
              variant="outlined"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              value={values.email}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              inputProps={{ style: inputStyle }}
              InputProps={{
                style: { borderRadius: '12px' },
                endAdornment: (
                  <InputAdornment position="end">
                    <MailOutlineTwoTone />
                  </InputAdornment>
                ),
              }}
            />


            <TextField
              margin="normal"
              label="What industry do you work in?"
              variant="outlined"
              name="industry"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              value={values.industry}
              error={touched.industry && Boolean(errors.industry)}
              helperText={touched.industry && errors.industry}
              inputProps={{ style: inputStyle }}
              InputProps={{
                style: { borderRadius: '12px' },
                endAdornment: (
                  <InputAdornment position="end">
                    <Apartment />
                  </InputAdornment>
                ),
              }}
            />


            <TextField
              label="What is the purpose for requirement?"
              variant="outlined"
              margin="normal"
              name="purpose"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              value={values.purpose}
              error={touched.purpose && Boolean(errors.purpose)}
              helperText={touched.purpose && errors.purpose}
              inputProps={{ style: inputStyle }}
              InputProps={{
                style: { borderRadius: '12px' },
                endAdornment: (
                  <InputAdornment position="end">
                    <LiveHelpOutlined />
                  </InputAdornment>
                ),
              }}
            />



            <TextField
              label="Phone no."
              variant="outlined"
              margin="normal"
              name="phone_no"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              value={values.phone_no}
              error={touched.phone_no && Boolean(errors.phone_no)}
              helperText={touched.phone_no && errors.phone_no}
              inputProps={{ style: inputStyle }}
              InputProps={{
                style: { borderRadius: '12px' },
                endAdornment: (
                  <InputAdornment position="end">
                    <LocalPhone />
                  </InputAdornment>
                ),
              }}
            />



            <FormControl fullWidth margin="normal">
              <InputLabel id="demo-simple-select-label">Security Question</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Security Question"
                onChange={handleChange}
                name="secretquestion"
                onBlur={handleBlur}
                sx={{ borderRadius: '12px' }}
                value={values.secretquestion}
                error={touched.secretquestion && Boolean(errors.secretquestion)}
              >
                <MenuItem value={'What was your favorite subject in high school?'}>What was your favorite subject in high school?</MenuItem>
                <MenuItem value='What is your first employee ID number?'>What is your first employee ID number?</MenuItem>
                <MenuItem value='Where did you go on your favorite vacation as a child?'>Where did you go on your favorite vacation as a child?</MenuItem>
                <MenuItem value='What is your graduation score?'>What is your graduation score?</MenuItem>
              </Select>
            </FormControl>



            <TextField
              label="Security Answer"
              margin="normal"
              variant="outlined"
              name="secretanswer"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              value={values.secretanswer}
              error={touched.secretanswer && Boolean(errors.secretanswer)}
              helperText={touched.secretanswer && errors.secretanswer}
              inputProps={{ style: inputStyle }}
              InputProps={{
                style: { borderRadius: '12px' },
                endAdornment: (
                  <InputAdornment position="end">
                    <SecurityOutlined />
                  </InputAdornment>
                ),
              }}
            />

            {error && (

              <Alert severity="error">
                {errMsg || 'Something went wrong !'}{' '}
              </Alert>

            )}

            <Stack direction="row" spacing={2} alignItems="center" mt={2}>

              <Button
                fullWidth
                variant="contained"
                color="primary"
                href="./login"
              >
                Back
              </Button>
              <Button
                fullWidth
                variant="contained"
                type='submit'
                color="secondary"
              >
                Submit
              </Button>

            </Stack>

          </Box>


        }

      </Box>
    </Container>
  )
}

export default Signup;