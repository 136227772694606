import LayOut from "./LayOut";
import './App.css'
import Login from "./Login";
import SignUp from "./SignUp";
import { createBrowserRouter  } from "react-router-dom";
import { RouterProvider } from 'react-router'
import CreationPage from "./CreationPage";
import PrivacyPolicy from "./PrivacyPolicy";
import PageNotFound from "./PageNotFound";
import TermsAndCondition from "./TermsAndCondition";
import NewsAi from "./NewAi";
const router = createBrowserRouter([
  {
    path: '/',
    element: <LayOut />,
    children: [
      
      {
        path: '/newswithoutai',
        element: <CreationPage />,
      },
      {
        path: '/newsai',
        element: <NewsAi />
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
      },
      {
        path:'*',
        element:<PageNotFound/>
      },
      {
        path: '/terms-condition',
        element: <TermsAndCondition />,
      },
    ],
    
  },
  {
    path: '/login',
    element: <Login />,    
  },
  {
    path:'/SignUp',
    element: <SignUp/>
  },
  {
    path:'*',
    element:<PageNotFound/>
  }
])

export const App = () => <RouterProvider router={router} />
