import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getStoragedData } from "./utils/Service";
import { aiFeatures178, aiFeatures179, aiFeatures138 } from "./utils/ExportData";
import ResultVideo from "./ResultVideo";

const NewsAi = () => {
    const [userId, setUserId] = useState(null);
    const [features, setFeatures] = useState<any>([])
    const [selected, setSelected] = useState<any>(null);

    const handleButtonClick = (val: any) => {
        setSelected(val);
    }

    useEffect(() => {
        const user_id = getStoragedData('user_id');
        if (user_id) {
            setUserId(user_id);
            user_id == "178" ? setFeatures((prev: any) => [...aiFeatures178]) : user_id == "138" ? setFeatures((prev: any) => [...aiFeatures138]) : (user_id == "179" ? setFeatures((prev: any) => [...aiFeatures179]) : setFeatures((prev: any) => [...aiFeatures179, ...aiFeatures178, ...aiFeatures138]))
        }
    }, []);
    return (
        <Box sx={{ mx: 2, textAlign: 'center', justifyContent: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography gutterBottom variant="h5" component="div">
                    Select Feature
                </Typography>
                <Divider
                    color="#a639a6" sx={{ height: 2, width: '92px', m: 'auto' }}
                />
                <Box sx={{width:"100%"}}>
                <Stack direction="row" spacing={2} sx={{ mt: 3, justifyContent: "center", flexWrap: "wrap" }}>
                    {features?.length && features.map((item: any, index: number) => (
                        <Box  key={item.id} >
                        <Button                            
                            color="secondary" 
                            variant={selected?.id == item.id ? "contained" : "outlined"} 
                            onClick={() => handleButtonClick(item)}
                            // sx={{ mt: index >= 0 && index <= 9 ? 2 : 0 }} // Add margin-top after the 10th button

                            sx={{marginBottom:1}}
                        >
                            {item.name}
                        </Button>
                        </Box>
                    ))}
                </Stack>
                </Box>
                
                {selected?.link && (
                    <Box sx={{ mt: 2 }}>
                        <video autoPlay controls src={selected?.link} width={"auto"} style={{ height: "50vh" }}>
                        </video>
                    </Box>
                )}
            </Box>
        </Box>
    )
    
    
}

export default NewsAi;