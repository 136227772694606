import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const DemoVideo = ({ open, handleClose }: any) => {

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Introduction Video"}
            </DialogTitle>
            <DialogContent>
                <video autoPlay controls src="https://botgeniusplusvideos.s3.amazonaws.com/AI+News+Channel.mp4" height={200} style={{ width: '100%' }}>
                </video>
            </DialogContent>

        </Dialog>
    )
}

export default DemoVideo;