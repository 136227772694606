import * as Yup from 'yup';

const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;

export const signupSchema = Yup.object({
  username: Yup.string().required('username is required.'),
  email: Yup.string().email().required('email is required.'),
  phone_no: Yup.string()
    .required('phone is required.')
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('User contact is required.'),
    industry: Yup.string().required('Industry is required.').min(3),
    purpose: Yup.string().required('Purpose is required.').min(3),
  secretquestion: Yup.string().required('Select security question'),
  secretanswer: Yup.string().required('Security answer required').max(255),

});

export const forgotSchema = Yup.object(
  {
    email: Yup.string().email().required('email is required.'),
    newpassword: Yup.string().required('Password is required.').min(7),
    secretquestion: Yup.string().required('Select security question'),
    secretanswer: Yup.string().required('Security answer required').max(255),
    confirmpassword: Yup.string().required('Confirm password is required.').min(7),
  }
)

export const signinSchema = Yup.object({
  EmailId: Yup.string().required('Email id is required.'),
  password: Yup.string().required('Password is required.').min(7),
});
