import { Box, Grid, Paper } from "@mui/material";
import UploadImage from "./UploadImage";
import UploadVideo from "./UploadVideo";

const UploadBox = ({ keyId, handleChange }: any) => {

    return (
        <Paper sx={{ my: 5, bgcolor: "#b015b04f" }}>
            <Grid container sx={{ width: "100%", mx: 0,justifyContent:"center" }}   >
                {/* <Grid item sm={12} xs={12} md={6} sx={{ p: 2 }}>
                    <UploadImage keyId={keyId} handleChange={handleChange} />
                </Grid> */}
                <Grid item sm={12} xs={12} md={4} sx={{ p: 2 }}>
                    <UploadVideo keyId={keyId} handleChange={handleChange} />
                </Grid>
            </Grid>

        </Paper>
    )
}

export default UploadBox;