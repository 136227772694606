import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import SelectAIType from "./SelectAIType";
import UploadBox from "./UploadBox";
import ResultVideo from "./ResultVideo";
import { useEffect, useState } from "react";
import TextBox from "./TextBox";
import { commonPostApi } from "./utils/ApiService";
import CountryType from "./CountryType";
import GenerateBtn from "./GenerateBtn";

const CreationPage = () => {
    const [object, setObject] = useState<any>({})
    const [output, setOutput] = useState<any>()
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState("")

    useEffect(()=>{
        setObject({
            "aiLink":"newswithoutai",
            "input_face": "https://botgeniusplusvideos.s3.amazonaws.com/Infogen+Labs+AI+News+anchor.mp4"
    })
    },[])

    const handleObj = (obj: any) => {
        setObject({ ...object, ...obj })
    }

    const handleCountry=(obj: any)=>{
        setLoader(true);
            setError("");
        const formData = new FormData();
        formData.append('input_face_url', obj.input_face)
        formData.append('country', obj.country);
        handleApiData(object.aiLink, formData)
    }

    const submit = () => {
        const formData = new FormData();
        if (object.aiLink && object.input_face && (object.text_prompt || (object.country && object.aiLink == "newsai"))) {
            setLoader(true);
            setError("");
            setOutput(null)
            if (["newswithoutai"].includes(object.aiLink)) {
                formData.append('input_face_url', object.input_face)
                // object.aiLink == "newsai" ? formData.append('country', object.country) :
                    formData.append('text_prompt', object.text_prompt)

            } else {
                formData.append('input_face', object.input_face)
                formData.append('text_prompt', object.text_prompt)
            }

            if (object.text_prompt) {
                setObject({ ...object, ...{ "text_prompt": "" } })
            }
           
            handleApiData(object.aiLink, formData)
        } else {
            console.error("All fields are requierd")
            setError("All fields are requierd");
        }
    }

    const handleApiData = (link : any, payload : any) =>{
        const result = commonPostApi(link, payload);
        result
            .then((res: any) => {

                if (res) {
                    setOutput(res)

                    setLoader(false);
                } else {
                    setError("Unable to get response");
                    setLoader(false);
                }
            })
            .catch((err) => {
                setError("Unable to get response");
                setLoader(false);
            });
    }

    return (
        <Box sx={{ mx: 2 }}>
            {/* <SelectAIType keyId={'aiLink'} handleChange={handleObj} /> */}
            {object.aiLink == "newsai" ?
                <>
                    <CountryType keyId={'country'} handleChange={handleCountry} />
                {loader &&  <Typography variant="h6" component="div" sx={{textAlign:"center"}} >Loading...</Typography>}
                    {/* <GenerateBtn loader={loader} submit={submit} /> */}
                </> :
                <>
                    {object.aiLink !== "newswithoutai" && <UploadBox keyId={'input_face'} handleChange={handleObj} />}
                    <TextBox keyId={'text_prompt'} handleChange={handleObj} submit={submit} loader={loader} />
                </>
            }

            {error ? <Box sx={{ mt: 3, textAlign: "center", color: "red" }}>
                <Typography variant="h6" component="div">
                    {error}
                </Typography>   </Box> : output? <ResultVideo output={output} />:""}
        </Box>

    )
}

export default CreationPage;